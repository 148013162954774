/* CSS File */
.hover-item:hover .delete-button {
    display: inline-block; /* Show the delete button when hovering over the item */
  }
  
  .delete-button {
    display: none; 
    background-color: "#D11A2A";
    color: "white";
    border-radius: "5px";
  }
  